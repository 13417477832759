<template>
  <div class="richmond">
    <m-header-richmond title="Night Market Coupon"></m-header-richmond>
    <div class="main">
      <div class="main-head">
        <img src="@/assets/richmond/RNM_2022-logo.png" alt="" />
      </div>
      <div class="main-head2">
         <div class="head-img">
            <div class="head_bg">
              <img src="../../../assets/richmond/Rectangle16.png" alt="" />
            </div>
            <div class="head_content">
              <p>*** *** ***</p>
              <div style="display: flex">
                <div>Give you coupons</div>
              </div>
            </div>
          </div>
      </div>
      <div class="coupon">
        <div class="box">
            <div class="item" v-for="(item, index) in img" :key="index">
                <img
                  :src="item.src"
                  alt=""
                />
            </div>
        </div>
      </div>
    </div>
    <div class="flex-btn">
      <div class="flex-item">
        <div @click="btnClick()">Login to Use</div>
      </div>
    </div>
  </div>
</template>

<script>
import MHeaderRichmond from "@/components/en/m-header-richmond.vue";
export default {
  name: "Index",
  components: {
    MHeaderRichmond,
  },
  data() {
    return {
      img: [
        {src:require('@/assets/richmond/coupon1.png')},
        {src:require('@/assets/richmond/coupon2.png')},
        {src:require('@/assets/richmond/coupon3.png')},
        {src:require('@/assets/richmond/coupon4.png')},
      ]
    };
  },
  methods: {
    btnClick() {
      window.location.href = 'https://richmond.moobyyoho.com/#/login'
    }
  }
};
</script>

<style scoped lang="less">
.richmond {
  font-size: 16px;
  font-family: FuturaBT-BoldItalic;
  li {
    list-style: none;
  }
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .main {
    width: 100%;
    background: url("../../../assets/richmond/couponbg.png");
    background-size: 100%;
    position: relative;
    .main-head {
      padding: 80px 0 0;
      img {
        display: block;
        margin: auto;
        width: 286px;
        height: 88px;
      }
    }
    .main-head2 {
      margin-top: 8px;
      padding-left: 30px;
      .head-img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .head_bg {
          z-index: 2;
          width: 80px;
          height: 80px;
          background: #5e00ba;
          border: 0.5px solid #000000;
          border-radius: 50%;
          img {
            width: 80px;
            height: 80px;
            position: relative;
            top: -4px;
            left: -4px;
            border-radius: 50%;
          }
        }
        .head_content {
          z-index: 1;
          padding: 10px;
          padding-left: 35px;
          width: 260px;
          height: 66px;
          position: relative;
          font-weight: 500;
          font-size: 18px;
          top: 13px;
          left: -30px;
          background-image: url("../../../assets/richmond/Vector.png");
          background-size: 100% 100%;
          font-weight: 600;
          p {
            padding-left: 4px;
          }
          div {
            line-height: 24px;
          }
        }
      }
      .head-map {
        display: block;
        width: 92%;
        margin: auto;
      }
    }
    .coupon {
      z-index: 1;
      position: relative;
      padding: 10px 2.5% 50px;
      .box {
        width: 94%;
        margin: 15px auto;
        background-image: url("../../../assets/richmond/Subtract11.png");
        width: 331px;
        height: 520px;
        background-size: 100% 100%;
        padding-top: 10px;
        .item {
          position: relative;
          margin-bottom: 10px;
          img {
            display: block;
            margin: auto;
            width: 307px;
            height: 120px;
          }
        }
      }
    }
  }
  .flex-btn {
    z-index: 9;
    bottom: 0;
    height: 60px;
    background-color: #030424ee;
    position: fixed;
    padding: 10px 10px;
    border-radius: 25px 25px 0 0;
    width: 100%;
    .flex-item {
      width: 70%;
      margin: auto;
      height: 40px;
      background: #089bd6;
      border: 1px solid rgba(51, 165, 255, 0);
      border-radius: 30px;
      div {
        position: relative;
        top: -2px;
        width: 100%;
        height: 38px;
        text-align: center;
        color: #fff;
        line-height: 38px;
        background: linear-gradient(
          180deg, rgba(51, 165, 255, 0) 20.83%, 
          rgba(77, 176, 255, 0.9) 100%), 
          #008EFF;
        border-radius: 30px;
      }
    }
  }
}
</style>